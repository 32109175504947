class Footer
	constructor: () ->
		@footer   = document.getElementById("footer")
		@body     = document.body
		@absolute = false

		@browsers = @footer.childNodes[0].childNodes[3].childNodes

		for browser in @browsers
			new HoverEffect(browser.childNodes[0])

		window.addEventListener("load", =>
			@refresh()
		,false)

		window.addEventListener("resize", =>
			@refresh()
		,false)

	refresh: () ->
		footerHeight = @footer.offsetHeight + 40
		windowHeight = window.innerHeight

		if @absolute
			bodyHeight = @body.offsetHeight + footerHeight
		else
			bodyHeight = @body.offsetHeight

		if bodyHeight > windowHeight
			@footer.style.position = "relative"
			@absolute = false
		else
			@footer.style.position = "absolute"
			@absolute = true

new Footer